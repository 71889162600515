#news{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url('../images/bg.png');
        background-repeat: repeat;
        padding: 0;
        opacity: 0.7;
        z-index: -1;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
    .card-title{
        color: #66B64D;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 25px;
    }
    .card-subtitle{
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: #1B1B1B;
        display: -webkit-box;
        font-size: 15px;
        height: 63px;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .card{
        max-width: 23.45%;
        margin-bottom: 20px;
        margin-right: 20px;
        background: #FFFFFF;
        box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
        border-radius: 0;
        position: relative;
        &:hover{
            img{
                transform: scale(1.07);
            }
        }
    }
    .card-header{
        height: 255px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition;
    }
    .card:first-child, .card:nth-child(2){
        display: flex;
        flex-direction: row;
        max-width: 48.43%;
        .card-header, .card-body{
            max-width: 50%;
        }
        img{
            height: auto;
        }
        .card-body{
            padding: 60px 20px;
        }
        .card-header{
            height: auto;
        }
        .card-title{
            color: #66B64D;
            font-weight: 800;
            line-height: 36px;
            font-size: 30px;
            margin-bottom: 15px;
        }
        .card-subtitle{
            font-weight: 500;
            color: #1B1B1B;
            font-size: 15px;
        }
        
    }
    .news-date{
        position: absolute;
        bottom: 40px;
        left: 20px;
        svg{
            color: #66B64D;
        }
        color: #3F3F3F;
    }
    .card-header{
        background: transparent;
        padding: 0;
        overflow: hidden;
    }
    .card-body{
        position: relative;  
        padding-bottom: 95px;
    }
    
}


#single-news{
    p{
        font-weight: 500;
        font-size: 18px;
    }
    .date-div{
        text-align: right;
        
    }
    .date{
        color: #909090;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        display: inline-flex;
        align-items: flex-start;
    }
    svg{
        color: #66B64D;
        font-size: 16px;
        margin-right: 5px;
    }
    .butun-xeber{
        margin-top: 10px;
        text-align: right;
        a{
            color: black;
            &:hover{
                color: #66B64D;
            }
        }
    }
}
