#home{
    padding: 0;
    position: relative;
    
    .slider-text{
        position: absolute;
        left: 0;
        right: 0;
        width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
        margin: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: white;
        top: 50%; 
        z-index: 9999;
    }
    .slider-img{
        width: 100%;
        min-height: 700px;
        object-fit: cover;
    }
    .slider-subtitle{
        color: #fff;
        font-size: 20px;
        margin-bottom: 0;
        margin-left: 250px;
        transition: all 0.8s ease-in-out;
    }
    .slider-title{
        color: #fff;
        font-family: $font1;
        font-weight: 350;
        font-size: 47px;
        max-width: 866px;
        width: 100%;
        margin-left: 250px;
        transition: all 0.9s ease-in-out;
    }
    .button{
        margin-top: 30px;
        margin-left: 250px;
        transition: all 1s ease-in-out;
    }
    .swiper{
        height: 700px;
    }
    .swiper-button-prev, .swiper-button-next{
        width: 10px;
        height: 10px;
        color: white;
        &:after{
            font-size: 23px;
        }
    }
    .swiper-button-next{
        right: 300px;
        bottom: 70px;
        top: auto;
    }
    .swiper-button-prev{
        right: 370px;
        left: auto;
        bottom: 70px;
        top: auto;
    }
    .pagination-div{
        position: absolute;
        right: 180px;
        bottom: 60px;
        width: 90px;
        height: 40px;
        overflow: hidden;
        span{
            color: white;
            font-size: 35px;
            font-family: $font1;
            transition: all 0.7s ease-in-out;
            position: absolute;
            top: -20px;
            bottom: auto;
        }
    }
    .swiper-pagination-total{
        display: none;
    }
    .swiper-slide-active{
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .slider-subtitle, .slider-title, .button{
            margin-left: 0;
        }
        .pagination-div span{
            top: 0;
            bottom: 5px;
        }
    }

}




#homeMenu{
    img{
        transition: $transition;
    }
    .col-lg-2{
        img{
            max-width: 224px;
            height: 210px;
            object-fit: cover;
            width: 100%;
            
        }
        &>div{
            position: relative;
            &:hover{
                img{
                    transform: scale(1.05);
                }
            }
        }
    }
    .col-lg-5{
        img{
            max-width: 525px;
            height: 505px;
            object-fit: cover;
            width: 100%;
            
        }
        &>div{
            position: relative;
            &:hover{
                img{
                    transform: scale(1.05);
                }
            }
        }
    }
    .home-menu-img{
        overflow: hidden;
        position: relative;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.15);
    }
    span{
        font-family: $font2;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
    }
    .menu-title{
        font-family: $font1;
        font-weight: 550;
        font-size: 22px;
        margin-top: 10px;
        margin-bottom:0px;
    }
    .menu-subtitle{
        display: flex;
        justify-content: space-between;
        svg{
            color: #466E61;
            font-size: 23px;
        }
    }
    
}

.play{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 88px;
    height: 88px;
    background: rgba(102, 182, 77, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    svg{
        color: white;
        font-size: 50px;
    }
}