@import url('http://fonts.cdnfonts.com/css/gotham-rounded');
$font1: 'Gotham Rounded', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
$font2: 'Nunito', sans-serif;

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}
a, button, i, svg{
    transition: $transition;

}
a, a:hover{
    text-decoration: none !important;
    white-space: nowrap;
}
input:focus,
textarea:focus {
    outline: none;
    box-shadow: none !important;
}
button{
    border: none;
}
button:focus{
    outline: none;
}
section{
    padding: 90px 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
h1, h2, h3, h4, h5, h6{
    font-family: $font1;
    color: $black;
    position: relative;
    z-index: 1;
}
p, span, a, button, li, label, button{
    font-family: $font2;
    color: $black;
    position: relative;
    z-index: 1;
}
input{
    position: relative;
    z-index: 1;
}
.title{
    color: $black;
    font-weight: 350;
    font-size: 32px;
    line-height: 38px;
}
.subtitle{
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;
}
.title-div{
    margin-bottom: 50px;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0px;
    background: transparent;
}

 ::-webkit-scrollbar-thumb {
    background: #F2FFF2;
    border-radius: 8px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: #F2FFF2;
}

.card-link{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}