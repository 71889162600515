@import url("http://fonts.cdnfonts.com/css/gotham-rounded");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a, button, i, svg {
  transition: all 0.4s linear;
}

a, a:hover {
  text-decoration: none !important;
  white-space: nowrap;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
}

button {
  border: none;
}

button:focus {
  outline: none;
}

section {
  padding: 90px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Gotham Rounded", sans-serif;
  color: #1B1B1B;
  position: relative;
  z-index: 1;
}

p, span, a, button, li, label, button {
  font-family: "Nunito", sans-serif;
  color: #1B1B1B;
  position: relative;
  z-index: 1;
}

input {
  position: relative;
  z-index: 1;
}

.title {
  color: #1B1B1B;
  font-weight: 350;
  font-size: 32px;
  line-height: 38px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}

.title-div {
  margin-bottom: 50px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #F2FFF2;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #F2FFF2;
}

.card-link {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.breadcrumbs {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  width: 100%;
  padding-top: 30px;
  position: relative;
}
.breadcrumbs .container {
  min-height: 490px;
  position: relative;
}
.breadcrumbs:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}
.breadcrumbs ul {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin: 0;
}
.breadcrumbs li {
  color: #fff;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 30px;
}
.breadcrumbs li, .breadcrumbs a {
  font-weight: 600;
}
.breadcrumbs a {
  color: #fff;
  position: relative;
  display: inline-block;
}
.breadcrumbs a:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  right: -18px;
  top: 10px;
  background: #66B64D;
}
.breadcrumbs a:hover {
  color: #FAC617;
}
.breadcrumbs .bred-subtitle, .breadcrumbs .bred-title {
  color: white;
}
.breadcrumbs .bred-subtitle {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}
.breadcrumbs .bred-title {
  font-family: "Gotham Rounded", sans-serif;
  font-weight: 350;
  font-size: 40px;
  max-width: 860px;
  line-height: 44px;
}
.breadcrumbs .bred-text {
  position: absolute;
  bottom: 40px;
}

#breadcrumbs-contact {
  background-image: url("../images/bred-elaqe.jpg");
}

#breadcrumbs-about {
  background-image: url("../images/bred-haqqimizda.jpg");
}

#breadcrumbs-news {
  background-image: url("../images/bred-news.jpg");
}

#breadcrumbs-news-single {
  background-image: url("../images/bred-news-single.jpg");
}

#breadcrumbs-vacancy {
  background-image: url("../images/vacancy-bred.jpg");
}

#breadcrumbs-gallery {
  background-image: url("../images/bred-gallery.jpg");
}

#breadcrumbs-product {
  background-image: url("../images/bred-products.jpg");
  background-position: center top;
}

header {
  z-index: 10001;
}
header img {
  height: 80px;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .header-top {
  background: #4a9433;
  padding: 9px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid white;
}
header .header-top > .container > div:first-child {
  display: flex;
  color: white;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
}
header .header-top > .container > div:first-child p {
  margin-bottom: 0;
  margin-left: 25px;
}
header .header-top > .container > div:first-child a, header .header-top > .container > div:first-child p {
  color: white;
  font-size: 15px;
  font-weight: 600;
}
header .header-top > .container > div:first-child a {
  font-size: 14px;
  position: relative;
}
header .header-top > .container > div:first-child a:before {
  content: "/";
  position: absolute;
  right: -13px;
  top: 0;
}
header .header-top > .container > div:first-child a:hover {
  color: #F2FFF2;
}
header .header-top > .container > div:last-child a {
  color: white;
}
header .header-top > .container > div:last-child svg {
  font-size: 19px;
}
header .header-top > .container > div:last-child .mail svg {
  font-size: 23px;
  margin-right: 24px;
}
header .header-top > .container > div:last-child .insta svg {
  font-size: 17px;
  margin-right: 20px;
}
header .header-bottom {
  padding: 15px 0;
  background: transparent;
  position: relative;
  z-index: 100001;
}
header .header-right {
  display: flex;
  align-items: center;
  width: 130px;
}
header .menu-btn-div {
  position: relative;
  cursor: pointer;
  z-index: 10000;
  width: 42px;
  height: 42px;
  margin-left: 32px;
}
header .menu-btn-div:before, header .menu-btn-div:after {
  content: "";
  background: #333;
  height: 2px;
  transition: all 0.4s linear;
  cursor: pointer;
  position: absolute;
}
header .menu-btn-div:before {
  transform: translateY(15px);
  width: 32px;
}
header .menu-btn-div:after {
  transform: translateY(27px);
  width: 18px;
}
header .menu-btn-div .menu-btn {
  background: #333;
  width: 42px;
  height: 2px;
  transition: all 0.4s linear;
  transform: translate(0px, 22px);
  width: 26px;
}
header .lang, header .lang li, header .lang:before {
  transition: all 0.4s linear;
  color: #333;
  cursor: pointer;
}
header .lang li:hover {
  color: #66B64D !important;
}

header.header-home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
header.header-home .menu-btn-div .menu-btn, header.header-home .menu-btn-div:after, header.header-home .menu-btn-div:before {
  background: white;
}
header.header-home .lang, header.header-home .lang li, header.header-home .lang:before {
  color: white;
}

.lang {
  display: flex;
  list-style: none;
  max-width: 58px;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;
  position: relative;
}
.lang:before {
  content: "/";
  position: absolute;
  left: 47%;
  color: white;
  top: -2px;
}
.lang li {
  font-weight: 600;
}
.lang .active {
  display: none;
}

header.show {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(92, 92, 92, 0.25);
  -webkit-animation: scroll 0.6s linear forwards;
          animation: scroll 0.6s linear forwards;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
header.show .lang, header.show .lang li, header.show .lang:before {
  color: #333;
}
header.show .menu-btn-div .menu-btn, header.show .menu-btn-div:after, header.show .menu-btn-div:before {
  background: #333;
}
header.show .header-top {
  box-shadow: none;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes scroll {
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}
header.show-header {
  background-color: transparent;
  box-shadow: none;
  transition: background-color 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header.show-header .header-top {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
header.show-header .menu-btn-div .menu-btn {
  margin-left: -20px;
  opacity: 0;
}
header.show-header .menu-btn-div:before {
  transform: translateY(15px) rotate(45deg);
}
header.show-header .menu-btn-div:after {
  transform: translateY(13px) rotate(135deg);
  width: 32px;
}
header.show-header .lang, header.show-header .lang li, header.show-header .lang:before {
  color: white;
}
header.show-header .menu-btn-div .menu-btn, header.show-header .menu-btn-div:after, header.show-header .menu-btn-div:before {
  background: white;
}

header .header-top {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

#home {
  padding: 0;
  position: relative;
}
#home .slider-text {
  position: absolute;
  left: 0;
  right: 0;
  width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
  transform: translateY(-50%);
  color: white;
  top: 50%;
  z-index: 9999;
}
#home .slider-img {
  width: 100%;
  min-height: 700px;
  -o-object-fit: cover;
     object-fit: cover;
}
#home .slider-subtitle {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
  margin-left: 250px;
  transition: all 0.8s ease-in-out;
}
#home .slider-title {
  color: #fff;
  font-family: "Gotham Rounded", sans-serif;
  font-weight: 350;
  font-size: 47px;
  max-width: 866px;
  width: 100%;
  margin-left: 250px;
  transition: all 0.9s ease-in-out;
}
#home .button {
  margin-top: 30px;
  margin-left: 250px;
  transition: all 1s ease-in-out;
}
#home .swiper {
  height: 700px;
}
#home .swiper-button-prev, #home .swiper-button-next {
  width: 10px;
  height: 10px;
  color: white;
}
#home .swiper-button-prev:after, #home .swiper-button-next:after {
  font-size: 23px;
}
#home .swiper-button-next {
  right: 300px;
  bottom: 70px;
  top: auto;
}
#home .swiper-button-prev {
  right: 370px;
  left: auto;
  bottom: 70px;
  top: auto;
}
#home .pagination-div {
  position: absolute;
  right: 180px;
  bottom: 60px;
  width: 90px;
  height: 40px;
  overflow: hidden;
}
#home .pagination-div span {
  color: white;
  font-size: 35px;
  font-family: "Gotham Rounded", sans-serif;
  transition: all 0.7s ease-in-out;
  position: absolute;
  top: -20px;
  bottom: auto;
}
#home .swiper-pagination-total {
  display: none;
}
#home .swiper-slide-active {
  position: relative;
}
#home .swiper-slide-active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
#home .swiper-slide-active .slider-subtitle, #home .swiper-slide-active .slider-title, #home .swiper-slide-active .button {
  margin-left: 0;
}
#home .swiper-slide-active .pagination-div span {
  top: 0;
  bottom: 5px;
}

#homeMenu img {
  transition: all 0.4s linear;
}
#homeMenu .col-lg-2 img {
  max-width: 224px;
  height: 210px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
#homeMenu .col-lg-2 > div {
  position: relative;
}
#homeMenu .col-lg-2 > div:hover img {
  transform: scale(1.05);
}
#homeMenu .col-lg-5 img {
  max-width: 525px;
  height: 505px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
#homeMenu .col-lg-5 > div {
  position: relative;
}
#homeMenu .col-lg-5 > div:hover img {
  transform: scale(1.05);
}
#homeMenu .home-menu-img {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
#homeMenu span {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
}
#homeMenu .menu-title {
  font-family: "Gotham Rounded", sans-serif;
  font-weight: 550;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 0px;
}
#homeMenu .menu-subtitle {
  display: flex;
  justify-content: space-between;
}
#homeMenu .menu-subtitle svg {
  color: #466E61;
  font-size: 23px;
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 88px;
  height: 88px;
  background: rgba(102, 182, 77, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.play svg {
  color: white;
  font-size: 50px;
}

#gallery {
  padding-top: 0;
  position: relative;
}
#gallery:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url("../images/bg.png");
  background-repeat: repeat;
  opacity: 0.7;
}
#gallery #photo {
  padding-top: 80px;
}
#gallery #photo .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#gallery #photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
  transition: all 0.4s linear;
}
#gallery #photo img:hover {
  transform: scale(1.07);
}
#gallery #photo .gallery-img-1 {
  max-width: 42.5%;
  min-height: 468px;
  max-height: 468px;
  overflow: hidden;
  width: 100%;
}
#gallery #photo .gallery-img-1:nth-child(1) {
  margin-bottom: 20px;
}
#gallery #photo .gallery-images:nth-child(2) {
  margin-bottom: 20px;
}
#gallery #photo .gallery-images {
  max-width: 56%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
#gallery #photo .gallery-img {
  max-width: 31.7%;
  width: 100%;
  min-height: 224px;
  max-height: 224px;
  overflow: hidden;
}
#gallery #video {
  padding-top: 80px;
  display: none;
}
#gallery #video .gallery-video {
  position: relative;
  max-width: 49%;
  min-height: 290px;
  max-height: 290px;
  width: 100%;
  margin-bottom: 25px;
  overflow: hidden;
}
#gallery #video img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
  transition: all 0.4s linear;
}
#gallery #video img:hover {
  transform: scale(1.07);
}
#gallery #video .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#gallery #photoLink {
  color: black;
}

.lightbox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 100000;
}
.lightbox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}
.lightbox > div img {
  width: 900px !important;
  height: 680px !important;
}
.lightbox .lightbox-box {
  position: relative;
  width: 1024px;
  height: 680px;
  text-align: center;
}
.lightbox .lightbox-box span {
  width: 900px !important;
}
.lightbox img {
  -o-object-fit: cover;
     object-fit: cover;
}
.lightbox #show {
  opacity: 1;
  visibility: visible;
}
.lightbox .left-btn {
  color: white;
  font-size: 34px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 160px;
}
.lightbox .right-btn {
  color: white;
  font-size: 34px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 160px;
}
.lightbox .dismiss-btn {
  color: white;
  font-size: 34px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.lightbox svg:hover {
  color: #66B64D;
}

footer {
  background: #466E61;
  position: relative;
  padding-top: 90px;
  overflow: hidden;
}
footer a, footer p, footer li {
  color: white;
  z-index: 2;
  position: relative;
}
footer a:hover, footer svg:hover {
  color: #FAC617 !important;
}
footer:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/bg-color.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.5;
}
footer ul {
  list-style: none;
  padding-left: 170px;
  display: flex;
  flex-wrap: wrap;
}
footer ul li {
  font-weight: 600;
  font-size: 19px;
  max-width: 50%;
  width: 100%;
  margin-bottom: 10px;
}
footer ul li a:hover {
  padding-left: 15px;
}
footer p {
  margin-bottom: 0;
}
footer .copyright {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  max-width: 55%;
}
footer .copyright a, footer .copyright p {
  font-size: 14px;
}
footer .copyright a {
  color: white;
}
footer .copyright img {
  height: 13px;
  filter: invert(1) brightness(2.5);
}
footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
footer .footer-bottom a:not(:last-child) {
  margin-right: 10px;
}
footer .footer-bottom img {
  margin-bottom: 15px;
  height: 100px;
}
footer .footer-bottom p {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}
footer .footer-bottom svg {
  color: white;
  font-size: 21px;
}
footer .footer-bottom a.mail {
  margin-right: 15px;
}
footer .footer-bottom a.mail svg {
  font-size: 25px;
}
footer .footer-bottom .insta svg {
  font-size: 19px;
}
footer .social-link {
  transform: translateY(20px);
}
footer .footer-contact p, footer .footer-contact a {
  font-size: 19px;
  margin-bottom: 10px;
}
footer .footer-contact > div {
  display: flex;
}
footer .footer-contact > div .contact {
  display: flex;
  flex-direction: column;
}
footer .footer-contact > div > a {
  margin-left: 50px;
}

#home-contact {
  background: #F2FFF2;
  position: relative;
}
#home-contact:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.8;
}
#home-contact .title {
  max-width: 580px;
  margin-bottom: 35px;
}
#home-contact div.container {
  max-width: 740px;
}
#home-contact form {
  display: flex;
  flex-wrap: wrap;
}
#home-contact form div {
  position: relative;
  max-width: 50%;
  width: 100%;
}
#home-contact form div:nth-child(3) {
  width: 100%;
  max-width: 100%;
  margin-top: 15px;
}
#home-contact form div:nth-child(2) input, #home-contact form div:nth-child(2) p {
  margin-left: 50px;
}
#home-contact form div:nth-child(2) label {
  left: 60px;
}
#home-contact form input, #home-contact form textarea {
  border: none;
  border-bottom: 1.5px solid #466E61;
  background: transparent;
  margin-top: 20px;
  padding-left: 10px;
  height: 30px;
}
#home-contact form label {
  color: #466E61;
}
#home-contact form button {
  width: 100%;
}
#home-contact label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.4s;
  font-size: 16px;
  font-weight: 400;
}
#home-contact textarea + label {
  bottom: -4px;
}
#home-contact input:focus, #home-contact textarea:focus {
  border-color: #1B1B1B;
}
#home-contact textarea {
  width: 100%;
}
#home-contact input {
  width: 305px;
}
#home-contact input:not(:-moz-placeholder-shown) + label, #home-contact textarea:not(:-moz-placeholder-shown) + label {
  top: 0;
  -moz-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
  color: #466E61;
}
#home-contact input:not(:-ms-input-placeholder) + label, #home-contact textarea:not(:-ms-input-placeholder) + label {
  top: 0;
  -ms-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
  color: #466E61;
}
#home-contact input:focus + label,
#home-contact input:not(:placeholder-shown) + label, #home-contact textarea:focus + label,
#home-contact textarea:not(:placeholder-shown) + label {
  top: 0;
  transition: 0.3s;
  background-color: transparent;
  color: #466E61;
}
#home-contact textarea:not(:-moz-placeholder-shown) + label {
  transform: translateY(-14%);
}
#home-contact textarea:not(:-ms-input-placeholder) + label {
  transform: translateY(-14%);
}
#home-contact textarea:focus + label,
#home-contact textarea:not(:placeholder-shown) + label {
  transform: translateY(-14%);
}
#home-contact .form-btn {
  border: 1.5px solid #466E61;
  border-radius: 70px;
  background: transparent;
  margin-top: 45px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 600;
}
#home-contact .form-btn:hover {
  background-color: #466E61;
  color: white;
}
#home-contact .Toastify {
  position: relative;
  z-index: 10000000;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

#contact {
  margin-bottom: 70px;
}
#contact .map {
  max-width: 600px;
  min-height: 580px;
  position: relative;
  width: 50%;
}
#contact .map:before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: -60px;
  border: 1px solid #1B1B1B;
  border-radius: 120px 0px;
}
#contact .map iframe {
  border-radius: 0 120px 0 0;
  min-height: 580px;
  border: 1px solid #1B1B1B;
  margin-left: 40px;
}
#contact .contact-info {
  background: #FFFBF4;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  width: 50%;
  min-height: 395px;
  padding: 50px 40px 50px 90px;
}
#contact .container {
  display: flex;
  align-items: center;
}
#contact h2, #contact h3 {
  font-family: "Nunito", sans-serif;
  color: #66B64D;
  font-weight: 600;
  margin-bottom: 20px;
}
#contact h2 {
  font-size: 32px;
}
#contact h3 {
  font-size: 22px;
  margin-top: 50px;
}
#contact p, #contact a {
  font-weight: 600;
  color: #000;
}
#contact p {
  margin-bottom: 15px;
}
#contact .contact-phone {
  display: flex;
  flex-wrap: wrap;
}
#contact .contact-phone a {
  margin-bottom: 8px;
}
#contact .contact-phone a:not(:last-child) {
  margin-right: 50px;
}
#contact .social-links {
  display: flex;
}
#contact .social-links a:not(:last-child) {
  margin-right: 50px;
}
#contact a:hover {
  color: #66B64D;
}

#home-about .subtitle {
  color: #466E61;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

#about-page {
  padding-top: 0;
  padding-bottom: 60px;
}

#home-about, #fealiyyet {
  overflow: hidden;
  position: relative;
  background: #F4F4F4;
  padding: 70px 140px 120px;
}
#home-about:before, #fealiyyet:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.7;
}
#home-about .title, #fealiyyet .title {
  margin-bottom: 15px;
}
#home-about .text, #fealiyyet .text {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  max-width: 530px;
}
#home-about .about-img, #fealiyyet .about-img {
  position: relative;
  max-width: 550px;
  height: auto;
  width: 100%;
  height: 100%;
}
#home-about .about-img:before, #fealiyyet .about-img:before {
  content: "";
  position: absolute;
  border-radius: 120px 0px;
  border: 1px solid #1B1B1B;
  left: -35px;
  top: 40px;
  bottom: -40px;
  right: 50px;
  transition: all 0.4s linear;
}
#home-about .about-img:hover:before, #fealiyyet .about-img:hover:before {
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}
#home-about img, #fealiyyet img {
  border-radius: 120px 0px;
  width: 100%;
  z-index: 1;
  position: relative;
}
#home-about .row, #fealiyyet .row {
  align-items: center;
}

.about-header {
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(92, 92, 92, 0.25);
  padding: 22px 0;
}
.about-header .container {
  text-align: center;
}
.about-header a {
  font-weight: 600;
  color: #909090;
  position: relative;
}
.about-header a:before {
  position: absolute;
  content: "";
  top: 8px;
  left: -10px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #909090;
}
.about-header a:not(:last-child) {
  margin-right: 50px;
}
.about-header a.active, .about-header a:hover {
  color: #1B1B1B;
}

#about {
  padding: 90px 0;
}
#about p {
  margin: auto;
  font-weight: 500;
  max-width: 956px;
  width: 100%;
  margin-bottom: 15px;
}

#partner {
  padding-top: 90px;
}
#partner .title {
  text-align: center;
  margin-bottom: 40px;
}
#partner .partner-img {
  display: flex;
  flex-wrap: wrap;
  max-width: 750px;
  margin: auto;
  width: 100%;
  justify-content: space-around;
}
#partner img {
  filter: grayscale(1);
  transition: all 0.4s linear;
  cursor: pointer;
  max-width: 140px;
  width: 100%;
  margin-bottom: 30px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 40px;
}
#partner img:hover {
  filter: grayscale(0);
}

.button {
  background: #66B64D;
  border: 1px solid #66B64D;
  border-radius: 70px;
  display: inline-block;
  color: white;
  padding: 9px 50px;
}
.button:hover {
  background: white;
  color: #66B64D;
}

.wp-btn, .top-btn {
  z-index: 100;
  width: 50px;
  height: 50px;
  background: #4a9433;
  border: 1px solid #4a9433;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s linear;
  position: fixed;
  right: 50px;
  bottom: 70px;
  cursor: pointer;
  transform: rotate(45deg);
}
.wp-btn:before, .wp-btn:after, .top-btn:before, .top-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #4a9433;
  z-index: -1;
  opacity: 0;
  -webkit-animation: pulse-border-2 1s linear infinite;
          animation: pulse-border-2 1s linear infinite;
}
.wp-btn:after, .top-btn:after {
  -webkit-animation: pulse-border 1.4s linear infinite;
          animation: pulse-border 1.4s linear infinite;
}
.wp-btn svg, .top-btn svg {
  color: white;
  font-size: 30px;
  transform: rotate(-45deg);
}
.wp-btn:hover, .top-btn:hover {
  background: white;
}
.wp-btn:hover svg, .top-btn:hover svg {
  color: #4a9433;
}

.wp-btn {
  bottom: 165px;
}

@-webkit-keyframes pulse-border-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
#home-products {
  padding: 70px 0;
}
#home-products .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  max-width: 386px;
  position: relative;
  margin: auto;
  padding: 30px;
  transition: all 0.4s linear;
}
#home-products .card:hover {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.13);
}
#home-products .card-link {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
#home-products img {
  width: 125px;
  height: 125px;
}
#home-products .card-title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-top: 40px;
  margin-bottom: 10px;
}
#home-products .card-subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

#products {
  padding-top: 0;
  position: relative;
}
#products:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: url("../images/bg.png");
  background-repeat: repeat;
  opacity: 0.7;
}
#products .products-container {
  padding-top: 90px;
}
#products .products-container .product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 85px 80px;
}
#products .products-container .product .product-img {
  max-width: 550px;
  position: relative;
}
#products .products-container .product .product-img:before {
  content: "";
  position: absolute;
  border-radius: 0px 120px;
  border: 1px solid #1B1B1B;
  left: -35px;
  top: 40px;
  bottom: -40px;
  right: 50px;
  transition: all 0.4s linear;
}
#products .products-container .product .product-img:hover:before {
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}
#products .products-container .product .product-img img {
  border-radius: 0px 120px;
  width: 100%;
  z-index: 1;
  position: relative;
  filter: drop-shadow(0px 1px 30px rgba(117, 117, 117, 0.25));
}
#products .products-container .product:last-child {
  padding-bottom: 0;
}
#products .products-container .product:nth-child(even) {
  flex-direction: row-reverse;
}
#products .products-container .product:nth-child(even) .product-img:before {
  border-radius: 120px 0px;
}
#products .products-container .product:nth-child(even) .product-img img {
  border-radius: 120px 0px;
}
#products .products-container .product-text {
  max-width: 490px;
}
#products .products-container .product-title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  position: relative;
  padding-left: 10px;
}

#news {
  position: relative;
}
#news:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/bg.png");
  background-repeat: repeat;
  padding: 0;
  opacity: 0.7;
  z-index: -1;
}
#news .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
#news .card-title {
  color: #66B64D;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 25px;
}
#news .card-subtitle {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #1B1B1B;
  display: -webkit-box;
  font-size: 15px;
  height: 63px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
#news .card {
  max-width: 23.45%;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  border-radius: 0;
  position: relative;
}
#news .card:hover img {
  transform: scale(1.07);
}
#news .card-header {
  height: 255px;
}
#news img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.4s linear;
}
#news .card:first-child, #news .card:nth-child(2) {
  display: flex;
  flex-direction: row;
  max-width: 48.43%;
}
#news .card:first-child .card-header, #news .card:first-child .card-body, #news .card:nth-child(2) .card-header, #news .card:nth-child(2) .card-body {
  max-width: 50%;
}
#news .card:first-child img, #news .card:nth-child(2) img {
  height: auto;
}
#news .card:first-child .card-body, #news .card:nth-child(2) .card-body {
  padding: 60px 20px;
}
#news .card:first-child .card-header, #news .card:nth-child(2) .card-header {
  height: auto;
}
#news .card:first-child .card-title, #news .card:nth-child(2) .card-title {
  color: #66B64D;
  font-weight: 800;
  line-height: 36px;
  font-size: 30px;
  margin-bottom: 15px;
}
#news .card:first-child .card-subtitle, #news .card:nth-child(2) .card-subtitle {
  font-weight: 500;
  color: #1B1B1B;
  font-size: 15px;
}
#news .news-date {
  position: absolute;
  bottom: 40px;
  left: 20px;
  color: #3F3F3F;
}
#news .news-date svg {
  color: #66B64D;
}
#news .card-header {
  background: transparent;
  padding: 0;
  overflow: hidden;
}
#news .card-body {
  position: relative;
  padding-bottom: 95px;
}

#single-news p {
  font-weight: 500;
  font-size: 18px;
}
#single-news .date-div {
  text-align: right;
}
#single-news .date {
  color: #909090;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  display: inline-flex;
  align-items: flex-start;
}
#single-news svg {
  color: #66B64D;
  font-size: 16px;
  margin-right: 5px;
}
#single-news .butun-xeber {
  margin-top: 10px;
  text-align: right;
}
#single-news .butun-xeber a {
  color: black;
}
#single-news .butun-xeber a:hover {
  color: #66B64D;
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: #00421D;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/bg-color.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.5;
}
#menu a, #menu h2, #menu svg, #menu p, #menu span {
  color: #FFFFFF;
}
#menu .container {
  display: flex;
  align-items: center;
  margin-top: 190px;
}
#menu ul {
  list-style: none;
  padding-left: 0;
  width: 49%;
}
#menu ul li {
  opacity: 0;
  transform: translate(-400px, 0px);
}
#menu ul a {
  display: inline-block;
  position: relative;
  font-size: 40px;
  margin-bottom: 10px;
}
#menu ul a:before {
  content: "";
  position: absolute;
  left: 0;
}
#menu ul a.active {
  color: #FAC617;
}
#menu ul li:nth-child(1) {
  transition: opacity 0.3s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu ul li:nth-child(2) {
  transition: opacity 0.3s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu ul li:nth-child(3) {
  transition: opacity 0.3s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu ul li:nth-child(4) {
  transition: opacity 0.3s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu ul li:nth-child(5) {
  transition: opacity 0.3s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu ul li:nth-child(6) {
  transition: opacity 0.3s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu .menu-contact > a {
  display: block;
  margin-bottom: 10px;
}
#menu .menu-container {
  width: 49%;
  transform: translateY(60px);
  opacity: 0;
  transition: opacity 0.5s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu .menu-container p {
  max-width: 550px;
  width: 100%;
  font-size: 18px;
}
#menu .title {
  font-size: 45px;
  margin-bottom: 20px;
}
#menu .social-link {
  display: flex;
  align-items: baseline;
  margin-top: 30px;
  opacity: 0;
  transition: opacity 0.5s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(70px);
}
#menu .social-link a {
  display: inline-block;
  height: 27px;
}
#menu .social-link .mail {
  font-size: 27px;
  margin-right: 30px;
}
#menu .social-link .insta {
  font-size: 20px;
  margin-right: 30px;
}
#menu .social-link .fb {
  font-size: 20px;
}
#menu .menu-contact {
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 70px;
  opacity: 0;
  transition: opacity 0.5s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(70px);
}
#menu .menu-contact a {
  margin-bottom: 0;
  font-size: 15px;
}
#menu .menu-contact a:first-child {
  margin-right: 10px;
  position: relative;
}
#menu .menu-contact a:first-child:before {
  content: "/";
  position: absolute;
  right: -13px;
  top: 0;
}
#menu .menu-contact a:nth-child(2) {
  margin-left: 10px;
}
#menu .menu-contact p {
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;
}
#menu a:hover, #menu svg:hover {
  color: #FAC617;
}

#menu.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  background: #00421D;
}
#menu.show:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../images/bg-color.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.5;
}
#menu.show ul li {
  opacity: 1;
  transform: translate(0px, 0px);
}
#menu.show ul li:nth-child(1) {
  transition: opacity 0.3s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show ul li:nth-child(2) {
  transition: opacity 0.3s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show ul li:nth-child(3) {
  transition: opacity 0.3s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show ul li:nth-child(4) {
  transition: opacity 0.3s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show ul li:nth-child(5) {
  transition: opacity 0.3s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show ul li:nth-child(6) {
  transition: opacity 0.3s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show .menu-container {
  transform: translateY(0px);
  opacity: 1;
  transition: opacity 0.5s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show .menu-contact {
  transform: translateY(0px);
  opacity: 1;
  transition: opacity 0.5s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 0.9s cubic-bezier(0.215, 0.61, 0.355, 1);
}
#menu.show .social-link {
  transform: translateY(0px);
  opacity: 1;
  transition: opacity 0.5s 1.1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s 1.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

#vacancy {
  transition: all 0.4s linear;
  position: relative;
}
#vacancy:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/bg.png");
  background-repeat: repeat;
  padding: 0;
  opacity: 0.7;
}
#vacancy .vacancy-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1220px;
  margin: auto;
}
#vacancy .vacancy-item {
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  width: 386px;
  margin-bottom: 20px;
  padding: 30px 20px;
  position: relative;
}
#vacancy .vacancy-item:hover .vacancy-btn {
  background: #66B64D;
  color: white;
}
#vacancy .vacancy-item:hover .vacancy-btn span {
  background: #FBFBFB;
  color: #66B64D;
}
#vacancy .vacancy-item:not(:nth-child(3n+0)) {
  margin-right: 20px;
}
#vacancy .vacancy-btn {
  background: #FBFBFB;
  box-shadow: 0px 2px 7px rgba(146, 146, 146, 0.25);
  border-radius: 70px;
  padding: 4px 25px 3px 0;
  margin-bottom: 30px;
  display: inline-block;
}
#vacancy .vacancy-btn span {
  background: #66B64D;
  border-radius: 70px 160px 0px 70px;
  padding: 8px 40px;
  color: white;
  transition: all 0.4s linear;
  margin-right: 10px;
}
#vacancy .vacancy-btn span svg {
  font-size: 30px;
}
#vacancy .vacancy-title {
  font-size: 20px;
  color: #66B64D;
  margin-bottom: 15px;
}
#vacancy .vacancy-date {
  text-align: right;
  font-size: 13px;
}
#vacancy .vacancy-date span {
  font-size: 13px;
  margin-right: 30px;
}
#vacancy .vacancy-subtitle {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 45px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#vacancy-contact {
  max-width: 1220px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  padding: 80px;
  position: relative;
  z-index: 1;
}
#vacancy-contact .title {
  color: #66B64D;
  margin-bottom: 30px;
  width: 100%;
}
#vacancy-contact p {
  margin-bottom: 15px;
  margin-top: 15px;
}
#vacancy-contact p, #vacancy-contact a {
  font-weight: 600;
}
#vacancy-contact p, #vacancy-contact a, #vacancy-contact svg {
  color: #000;
}
#vacancy-contact .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
#vacancy-contact .container .vacancy-contact-text {
  position: relative;
}
#vacancy-contact .container .vacancy-contact-text::before {
  content: "";
  background: #CBCBCB;
  transform: rotate(90deg);
  width: 85px;
  height: 1px;
  position: absolute;
  right: 0;
  right: -130px;
  top: 39px;
}
#vacancy-contact h3 {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  color: #66B64D;
  font-weight: 600;
  margin-bottom: 30px;
}
#vacancy-contact .social-link a:not(:last-child) {
  margin-right: 50px;
}
#vacancy-contact a:hover, #vacancy-contact a:hover svg {
  color: #66B64D;
}
#vacancy-contact .contact-phone a:not(:last-child) {
  margin-right: 50px;
}

.vacancy-single-section, .vacancy-contact-page {
  position: relative;
}
.vacancy-single-section:before, .vacancy-contact-page:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/bg.png");
  background-repeat: repeat;
  padding: 0;
  opacity: 0.7;
  z-index: -1;
}

.vacancy-single-section {
  padding-bottom: 110px;
}

.vacancy-contact-page {
  padding-bottom: 80px;
}

#vacancy-single {
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  padding: 10px 80px 60px;
  max-width: 995px;
  margin: auto;
  margin-top: 100px;
}
#vacancy-single .vacancy-title {
  font-weight: 700;
  font-size: 20px;
  color: #66B64D;
  margin-top: 50px;
}
#vacancy-single .vacancy-txt {
  font-weight: 600;
  font-size: 16px;
  color: #3F3F3F;
}
#vacancy-single ul {
  list-style: none;
  padding-left: 19px;
}
#vacancy-single ul li {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  color: #3F3F3F;
}
#vacancy-single ul li::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #3F3F3F;
  left: -11px;
  top: 9px;
}
#vacancy-single ul li:not(:last-child) {
  margin-bottom: 7px;
}
#vacancy-single .button {
  margin-top: 22px;
}

.modal-container {
  background: rgba(149, 149, 149, 0.4);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container .modal-box {
  max-width: 792px;
  width: 100%;
  padding: 50px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
  position: relative;
}
.modal-container .modal-box > svg {
  color: #66B64D;
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 22px;
  cursor: pointer;
}
.modal-container .modal-title {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin-bottom: 50px;
}
.modal-container label {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #69768B;
  padding-left: 20px;
  margin-bottom: 3px;
}
.modal-container input, .modal-container select {
  background: #FFFFFF;
  box-shadow: 0px 2px 7px rgba(146, 146, 146, 0.25);
  border-radius: 40px;
  border: 1px solid white;
  transition: all 0.4s linear;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 40px;
}
.modal-container input:focus, .modal-container select:focus {
  border-color: #66B64D;
}
.modal-container form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.modal-container form .button {
  margin-top: 0 !important;
}
.modal-container form > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.modal-container form > div:nth-child(1), .modal-container form > div:nth-child(2), .modal-container form > div:nth-child(6), .modal-container form > div:nth-child(7) {
  width: 48%;
}
.modal-container form > div:nth-child(3), .modal-container form > div:nth-child(4), .modal-container form > div:nth-child(5) {
  width: 31%;
}
.modal-container form > div:nth-child(8), .modal-container form > div:nth-child(9) {
  width: 100%;
}
.modal-container form > div:nth-child(8) {
  background: #F5F5F5;
  border: 1px dashed #D7D7DA;
  border-radius: 20px;
  position: relative;
}
.modal-container form > div:nth-child(8) input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.modal-container form > div:nth-child(8) label {
  display: block;
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
}

.vacancy-form-bottom {
  flex-direction: row !important;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {
  .menu-btn-div:hover:after, .menu-btn-div:hover .menu-btn {
    width: 32px;
  }
}
@media screen and (max-width: 1440px) {
  #homeMenu .col-lg-2 img {
    height: 183px;
  }
  #homeMenu .col-lg-5 img {
    height: 450px;
  }
  #home-about .about-img, #fealiyyet .about-img {
    margin-left: 30px;
  }
  #news .card:first-child, #news .card:nth-child(2) {
    max-width: 48%;
  }
  #news .card:first-child .card-title, #news .card:nth-child(2) .card-title {
    line-height: 28px;
    font-size: 25px;
  }
  #news .card {
    max-width: 23%;
  }
  #vacancy .vacancy-item {
    width: 32%;
  }
  #products .products-container .product {
    padding: 0 0px 80px;
  }
  .breadcrumbs .container {
    min-height: 360px;
  }
}
@media screen and (max-width: 1200px) {
  .about-header .container {
    overflow: auto;
  }
  .about-header .container::-webkit-scrollbar {
    width: 0px;
    height: 2px;
  }
  .about-header .container::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0px;
    background: transparent;
  }
  .about-header .container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }
  #products .products-container .product .product-text {
    padding-left: 50px;
  }
  #products .products-container .product:nth-child(even) .product-text {
    padding-left: 0px;
    padding-right: 50px;
  }
  #menu ul a {
    font-size: 35px;
    margin-bottom: 5px;
  }
  #menu .title {
    font-size: 35px;
    margin-bottom: 15px;
  }
  #gallery #photo .gallery-img {
    min-height: 200px;
    max-height: 200px;
  }
  #gallery #photo .gallery-img-1 {
    min-height: 415px;
    max-height: 415px;
  }
  #vacancy-contact .social-link a:not(:last-child) {
    margin-right: 20px;
  }
  #vacancy-contact .contact-phone a:not(:last-child) {
    margin-right: 3%;
  }
  #vacancy-contact .container .vacancy-contact-text::before {
    display: none;
  }
  #vacancy .vacancy-list {
    justify-content: space-evenly;
  }
  #vacancy .vacancy-item {
    max-width: 386px;
    width: 100%;
    margin-right: 0px !important;
  }
  #news .card:first-child, #news .card:nth-child(2) {
    max-width: 47%;
  }
  #news .card:first-child .card-title, #news .card:nth-child(2) .card-title {
    line-height: 25px;
    font-size: 23px;
  }
  #news .card {
    max-width: 22.4%;
  }
  #news .card span {
    font-size: 14px;
  }
  #news .card .card-title {
    font-size: 19px;
  }
  #homeMenu .col-lg-2 img {
    height: 153px;
  }
  #homeMenu .col-lg-5 img {
    height: 390px;
  }
  footer ul {
    padding-left: 90px;
  }
}
@media screen and (max-width: 991px) {
  #products .products-container .product {
    flex-direction: column !important;
  }
  #products .products-container .product .product-text {
    padding: 90px 0 0 !important;
    max-width: 550px;
  }
  #menu.show:before {
    background-size: cover;
  }
  #gallery #photo .gallery-img-1, #gallery #photo .gallery-images {
    max-width: 100%;
  }
  #gallery #photo .gallery-images:nth-child(2) {
    margin-bottom: 0;
  }
  #gallery #photo .gallery-img {
    margin-bottom: 20px;
  }
  #contact {
    margin-bottom: 0;
  }
  #contact .container {
    flex-direction: column;
  }
  #contact .map {
    width: 100%;
    margin-bottom: 110px;
    padding-right: 40px;
  }
  #contact .map:before {
    right: 40px;
  }
  #contact .contact-info {
    width: 100%;
    padding: 50px 40px;
    min-height: 350px;
  }
  #vacancy-contact .container {
    flex-direction: column;
    align-items: flex-start;
  }
  #vacancy-contact .container .vacancy-contact-text::before {
    display: none;
  }
  #vacancy-contact h3 {
    margin-top: 40px;
    margin-bottom: 15px;
  }
  #news .card:first-child .card-title, #news .card:nth-child(2) .card-title {
    font-size: 19px;
    margin-bottom: 10px;
  }
  #news .card:first-child .card-header, #news .card:nth-child(2) .card-header {
    height: 255px;
  }
  #news .card:first-child .card-body, #news .card:nth-child(2) .card-body {
    padding: 16px 16px 90px 16px;
  }
  #news span svg {
    margin-top: -4px;
  }
  #news .card:first-child, #news .card:nth-child(2) {
    flex-direction: column;
  }
  #news .card:first-child .card-header, #news .card:first-child .card-body, #news .card:nth-child(2) .card-header, #news .card:nth-child(2) .card-body {
    max-width: 100%;
  }
  #news .card {
    max-width: 47%;
  }
  #partner img {
    padding: 0 10px;
    margin-right: 0;
  }
  .breadcrumbs .bred-title {
    font-size: 40px;
    line-height: 48px;
  }
  #home-products .col-lg-4:not(:last-child) {
    margin-bottom: 65px;
  }
  #home-about .about-img, #fealiyyet .about-img {
    margin: auto;
  }
  #home-about .col-lg-6:first-child, #fealiyyet .col-lg-6:first-child {
    margin-bottom: 50px;
  }
  #homeMenu .col-lg-2 img, #homeMenu .col-lg-5 img {
    height: 360px;
    max-width: 100%;
  }
  #homeMenu .col-lg-2 > div, #homeMenu .col-lg-5 > div {
    margin-top: 40px;
  }
  footer ul {
    padding-left: 0;
  }
  footer .copyright {
    max-width: 100%;
  }
  footer .footer-contact {
    margin-top: 15px;
  }
}
@media screen and (max-width: 767px) {
  header .header-top > .container > div:first-child p, header .header-top > .container > div:first-child a:before, #menu .menu-contact a:first-child:before {
    display: none;
  }
  #menu .container {
    flex-direction: column;
    margin-top: 195px;
  }
  #menu .title, #menu .menu-container p {
    display: none;
  }
  #menu .menu-contact a:nth-child(2) {
    margin-left: 0;
    margin-top: 6px;
  }
  #menu ul a {
    font-size: 30px;
    margin-bottom: 2px;
  }
  header .container, header .header-top > .container > div:first-child {
    flex-wrap: wrap;
  }
  header .header-top > .container > div:first-child {
    margin-bottom: 8px;
  }
  #gallery #video .gallery-video {
    max-width: 100%;
  }
  #vacancy-single {
    padding: 10px 10px 60px;
  }
  .vacancy-contact-text .contact-phone {
    display: flex;
    flex-direction: column;
  }
  #vacancy-contact .contact-phone a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 7px;
  }
  #news .container {
    justify-content: space-between;
  }
  #news .card {
    margin-right: 0;
  }
  .breadcrumbs .bred-title {
    font-size: 35px;
    line-height: 40px;
  }
  #home-about, #fealiyyet {
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    font-size: 26px;
    line-height: 32px;
  }
  #home-contact form div:nth-child(2) input {
    margin-left: 0px;
  }
  #home-contact input {
    width: 100%;
  }
  #home-contact form div:nth-child(2) label {
    left: 10px;
  }
  #home-contact form {
    justify-content: space-between;
  }
  #home-contact form div {
    max-width: 47%;
  }
}
@media screen and (max-width: 575px) {
  #products .products-container .product .product-text {
    padding: 70px 27px 0 !important;
  }
  #products .products-container .product .product-img {
    padding: 0 27px;
  }
  #products .products-container .product .product-img:before {
    left: 10px;
    top: 30px;
    bottom: -20px;
  }
  #menu.show {
    overflow: auto;
  }
  #menu.show::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
    width: 0;
  }
  #menu.show::-webkit-scrollbar {
    width: 0px;
  }
  #menu ul a {
    font-size: 24px;
  }
  #menu .menu-contact {
    margin-top: 30px;
  }
  header .header-top > .container > div:first-child {
    margin-bottom: 0;
  }
  header .header-top > .container > div:last-child .mail svg {
    margin-right: 15px;
  }
  header .header-top > .container > div:last-child .insta svg {
    margin-right: 10px;
  }
  header img {
    height: 70px;
  }
  header .menu-btn-div {
    margin-left: 20px;
  }
  #gallery #photo .gallery-img {
    max-width: 48%;
    min-height: 180px;
    max-height: 180px;
  }
  #gallery #photo .gallery-img-1 {
    min-height: 300px;
    max-height: 300px;
  }
  .play {
    width: 65px;
    height: 65px;
  }
  .play svg {
    font-size: 40px;
  }
  .breadcrumbs .container {
    min-height: 400px;
  }
  #contact .map iframe, #contact .map {
    min-height: 460px;
  }
  #contact .social-links {
    flex-wrap: wrap;
  }
  #contact .social-links a:not(:last-child) {
    margin-bottom: 7px;
  }
  #contact .contact-info {
    padding: 50px 25px;
  }
  #contact h2 {
    font-size: 27px;
  }
  #contact h3 {
    font-size: 20px;
    margin-top: 35px;
  }
  .modal-container {
    overflow: auto;
  }
  .modal-container ::-webkit-scrollbar-thumb {
    background: transparent;
    width: 0;
  }
  .modal-container::-webkit-scrollbar {
    width: 0px;
  }
  .modal-container .modal-box {
    padding: 50px 20px;
  }
  .modal-container form > div {
    width: 100% !important;
  }
  #vacancy-contact {
    padding: 70px 10px;
  }
  #vacancy-contact .title {
    margin-bottom: 10px;
  }
  #vacancy-contact .social-link {
    display: flex;
    flex-wrap: wrap;
  }
  #vacancy-contact .social-link a:not(:last-child) {
    margin-bottom: 7px;
  }
  #single-news p {
    font-size: 16px;
  }
  #news .card {
    margin: auto;
    margin-bottom: 30px;
    max-width: 305px !important;
  }
  .breadcrumbs .bred-title {
    font-size: 27px;
    line-height: 30px;
  }
  #home-products img {
    width: 90px;
    height: 90px;
  }
  #home-products .card-link {
    margin-top: 30px;
  }
  #home-about .text, #fealiyyet .text {
    font-size: 17px;
  }
  #home-about .about-img:before, #fealiyyet .about-img:before {
    left: 0px;
    bottom: -30px;
    border-radius: 50px 0;
  }
  #home-about img, #fealiyyet img {
    border-radius: 50px 0;
  }
  #home-about .about-img, #fealiyyet .about-img {
    padding-left: 30px;
  }
  #homeMenu .col-lg-2 img, #homeMenu .col-lg-5 img {
    height: 300px;
  }
  .title {
    font-size: 25px;
    line-height: 29px;
  }
  .subtitle {
    font-size: 17px;
    margin-bottom: 3px;
  }
  #home-contact .title {
    margin-bottom: 5px;
  }
  #home-contact form {
    flex-direction: column;
  }
  #home-contact form div {
    max-width: 100%;
    margin-top: 20px;
  }
  #home-contact form input {
    width: 100%;
  }
  .wp-btn, .top-btn {
    right: 20px;
  }
  footer ul li {
    font-size: 17px;
    margin-bottom: 5px;
  }
  footer p {
    margin-bottom: 10px;
  }
  footer .copyright {
    margin-top: 20px;
    flex-direction: column;
  }
  footer .footer-bottom {
    margin-top: 70px;
  }
  footer .footer-bottom p {
    font-size: 18px;
    line-height: 18px;
  }
  footer .footer-contact p, footer .footer-contact a {
    font-size: 16px;
  }
  footer .footer-contact > div {
    flex-direction: column;
  }
  footer .footer-contact > div > a {
    margin-left: 0;
  }
  .vacancy-form-bottom {
    flex-direction: column !important;
  }
}/*# sourceMappingURL=main.css.map */