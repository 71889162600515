#gallery{
    padding-top: 0;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url('../images/bg.png');
        background-repeat: repeat;
        opacity: 0.7;
    }
    #photo{
        padding-top: 80px;
        .container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            transition: $transition;
            &:hover{
                transform: scale(1.07);
            }
        }
        .gallery-img-1{
            max-width: 42.5%;
            min-height: 468px;
            max-height: 468px;
            overflow: hidden;
            width: 100%;
        }
        .gallery-img-1:nth-child(1){
            margin-bottom: 20px;
        }
        .gallery-images:nth-child(2){
            margin-bottom: 20px;
        }
        .gallery-images{
            max-width: 56%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;
        }
        .gallery-img{
            max-width: 31.7%;
            width: 100%;
            min-height: 224px;
            max-height: 224px;
            overflow: hidden;
        }
        
    }
    #video{
        padding-top: 80px;
        display: none;
        .gallery-video{
            position: relative;
            max-width: 49%;
            min-height: 290px;
            max-height: 290px;
            width: 100%;
            margin-bottom: 25px;
            overflow: hidden;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            transition: $transition;
            &:hover{
                transform: scale(1.07);
            }
        }
        .container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

    }
    #photoLink{
        color: black;
    }
}


    .lightbox{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0,0,0,0.7);
        backdrop-filter: blur(10px);
        z-index: 100000;
        &>div{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            position: relative;
            img{
                width: 900px !important;
                height: 680px !important;
            }
        }
        .lightbox-box{
            position: relative;
            width: 1024px;
            height: 680px;
            text-align: center;
            span{
                width: 900px !important;
            }
        }
            img{
                object-fit: cover;
            }
            #show{
                opacity: 1;
                visibility: visible;
            }
            .left-btn{
                color: white;
                font-size: 34px;
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 160px;
            }
            .right-btn{
                color: white;
                font-size: 34px;
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 160px;
            }
            .dismiss-btn{
                color: white;
                font-size: 34px;
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
            }
            svg:hover{
                color:#66B64D;
            }
    }
    
