@media screen and (min-width: 1200px){
    .menu-btn-div:hover{
        &:after, .menu-btn{
            width: 32px;
        }
    }
}
@media screen and (max-width: 1440px){
    #homeMenu .col-lg-2 img{
        height: 183px;
    }
    #homeMenu .col-lg-5 img{
        height: 450px;
    }
    #home-about .about-img, #fealiyyet .about-img{
        margin-left: 30px;
    }
    #news .card:first-child, #news .card:nth-child(2){
        max-width: 48%;
        .card-title{
            line-height: 28px;
            font-size: 25px;
        }
    }
    #news .card{
        max-width: 23%;
    }
    #vacancy .vacancy-item{
        width: 32%;
    }
    #products .products-container .product{
        padding: 0 0px 80px;
        
    }
    .breadcrumbs .container{
        min-height: 360px;
    }
}
@media screen and (max-width: 1200px){
    .about-header .container{
        overflow: auto;
        &::-webkit-scrollbar {
            width:0px;
            height: 2px;
        }
        
        &::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 0px;
            background: transparent;
        }
        
         &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 0px;
        }
    }
    #products .products-container .product{
        .product-text{
            padding-left: 50px;
        }
        &:nth-child(even){
            .product-text{
                padding-left: 0px;
                padding-right: 50px;
            }
        }
    }
    #menu ul a{
        font-size: 35px;
        margin-bottom: 5px;
    }
    #menu .title {
        font-size: 35px;
        margin-bottom: 15px;
    }
    #gallery #photo .gallery-img{
        min-height: 200px;
        max-height: 200px;
    }
    #gallery #photo .gallery-img-1{
        min-height: 415px;
        max-height: 415px;
    }
    #vacancy-contact .social-link a:not(:last-child){
        margin-right: 20px;
    } #vacancy-contact .contact-phone a:not(:last-child){
        margin-right: 3%;
    }
    #vacancy-contact .container .vacancy-contact-text::before{
        display: none;
    }
    #vacancy .vacancy-list{
        justify-content: space-evenly;
    }
    #vacancy .vacancy-item{
        max-width: 386px;
        width: 100%;
        margin-right: 0px !important;
    }
    #news .card:first-child, #news .card:nth-child(2){
        max-width: 47%;
        .card-title{
            line-height: 25px;
            font-size: 23px;
        }
        
    }
    #news .card{
        max-width: 22.4%;
        span{
            font-size: 14px;
        }
        .card-title{
            font-size: 19px;
        }
    }
    #homeMenu .col-lg-2 img{
        height: 153px;
    }
    #homeMenu .col-lg-5 img{
        height: 390px;
    }
    footer ul{
        padding-left: 90px;
    }
}
@media screen and (max-width: 991px){
    
    #products .products-container .product{
        flex-direction: column !important;
        .product-text{
            padding: 90px 0 0 !important;
            max-width: 550px;
        }
    }
    #menu.show:before{
        background-size: cover;
    }
    #gallery #photo .gallery-img-1, #gallery #photo .gallery-images{
        max-width: 100%;
    }
    #gallery #photo .gallery-images:nth-child(2){
        margin-bottom: 0;
    }
    #gallery #photo .gallery-img{
        margin-bottom: 20px;
    }
    #contact{
        margin-bottom: 0;
    }
    #contact .container{
        flex-direction: column;
    }
   
    #contact .map{
        width: 100%;
        margin-bottom: 110px;
        padding-right: 40px;
        &:before{
            right: 40px;
        }
    }
    #contact .contact-info{
        width: 100%;
        padding: 50px 40px;
        min-height: 350px;
    }
    #vacancy-contact .container{
        flex-direction: column;
        align-items: flex-start;
    }
    #vacancy-contact .container .vacancy-contact-text::before{
        display: none;
    }
    #vacancy-contact h3{
        margin-top: 40px;
        margin-bottom: 15px;
    }
    #news .card:first-child, #news .card:nth-child(2){
        .card-title{
            font-size: 19px;
            margin-bottom: 10px;
        }
        .card-header{
            height: 255px;
        }
        .card-body{
            padding: 16px 16px 90px 16px;
        }
    }
    #news span svg{
        margin-top: -4px;
    }
    #news .card:first-child, #news .card:nth-child(2){
        flex-direction: column;
        .card-header, .card-body{
            max-width: 100%;
        }
    }
    #news .card{
        max-width: 47%;
    }
    #partner img{
        padding: 0 10px;
        margin-right: 0;
    }
    .breadcrumbs .bred-title{
        font-size: 40px;
        line-height: 48px;
    }
    #home-products{
        .col-lg-4:not(:last-child){
            margin-bottom: 65px;
        }
    }
    #home-about .about-img, #fealiyyet .about-img{
        margin: auto;
    }
    #home-about, #fealiyyet{
        .col-lg-6:first-child{
            margin-bottom: 50px;
        }
    }
    #homeMenu .col-lg-2 img, #homeMenu .col-lg-5 img{
        height: 360px;
        max-width: 100%;
        
    }
    #homeMenu .col-lg-2>div, #homeMenu .col-lg-5>div{
        margin-top: 40px;
    }
    footer{
        ul{
            padding-left: 0;
        }
        .copyright{
            max-width: 100%;
        }
        .footer-contact{
            margin-top: 15px;
        }
    } 
}
@media screen and (max-width: 767px){
    header .header-top > .container > div:first-child p, header .header-top > .container > div:first-child a:before, #menu .menu-contact a:first-child:before{
        display: none;
    }
    #menu {
        .container{
            flex-direction: column;
            margin-top: 195px;
        }
        .title, .menu-container p{
            display: none;
        }
        .menu-contact a:nth-child(2){
            margin-left: 0;
            margin-top: 6px;
        }
        ul a {
            font-size: 30px;
            margin-bottom: 2px;
        }
    }
    
    header .container, header .header-top > .container > div:first-child{
        flex-wrap: wrap;
    }
    header .header-top > .container > div:first-child{
        margin-bottom: 8px;
    }
    #gallery #video .gallery-video{
        max-width: 100%;
    }
    #vacancy-single{
        padding: 10px 10px 60px;
    }
    .vacancy-contact-text .contact-phone{
        display:flex;
        flex-direction: column;
    }
    #vacancy-contact .contact-phone a:not(:last-child){
        margin-right: 0;
        margin-bottom: 7px;
    }
    #news .container{
        justify-content: space-between;
    }
    #news .card{
        margin-right: 0;
    }
    .breadcrumbs .bred-title{
        font-size: 35px;
        line-height: 40px;
    }
    #home-about, #fealiyyet{
        padding-left: 0;
        padding-right: 0;
    }
    .title{
        font-size: 26px;
        line-height: 32px;
    }
    #home-contact form div:nth-child(2) input{
        margin-left: 0px;
    }
    #home-contact input{
        width: 100%;
    }
    #home-contact form div:nth-child(2) label{
        left: 10px;
    }
    #home-contact form{
        justify-content: space-between;
        div{
            max-width: 47%;
        }
    }
}
@media screen and (max-width: 575px){
    #products {
        .products-container .product {
            .product-text{
                padding: 70px 27px 0 !important;
            }
            .product-img{
                padding: 0 27px;
                &:before{
                    left: 10px;
                    top: 30px;
                    bottom: -20px;
                }
            }
        }
    }
    #menu.show{
        overflow: auto;
        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 0px;
            width: 0;
        }
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    #menu ul a{
        font-size: 24px;
    }
    #menu .menu-contact{
        margin-top: 30px;
    }
    header .header-top > .container > div:first-child{
        margin-bottom: 0;
    }
    header .header-top > .container > div:last-child .mail svg{
        margin-right: 15px;
    }
    header .header-top > .container > div:last-child .insta svg{
        margin-right: 10px;
    }
    header img{
        height: 70px;
    }
    header .menu-btn-div{
        margin-left: 20px;
    }
    #gallery #photo .gallery-img{
        max-width: 48%;
        min-height: 180px;
        max-height: 180px;
    }
    #gallery #photo .gallery-img-1{
        min-height: 300px;
        max-height: 300px;
    }
   
    .play{
        width: 65px;
        height: 65px;
        svg{
            font-size: 40px;
        }
    }
    .breadcrumbs .container{
        min-height: 400px;
    }
    #contact .map iframe, #contact .map{
        min-height: 460px;
    }
    #contact .social-links{
        flex-wrap: wrap;
    }
    #contact .social-links a:not(:last-child){
        margin-bottom: 7px;
    }
    #contact .contact-info{
        padding: 50px 25px;
    }
    #contact h2{
        font-size: 27px;
    }
    #contact h3 {
        font-size: 20px;
        margin-top: 35px;
    }
    .modal-container{
        overflow: auto;
        & ::-webkit-scrollbar-thumb {
            background: transparent;
            width: 0;
        }
        &::-webkit-scrollbar {
            width: 0px;
        }
        .modal-box{
            padding: 50px 20px;
        }
    }
    .modal-container form > div{
        width: 100% !important;
    }
    #vacancy-contact{
        padding: 70px 10px;
        .title{
            margin-bottom: 10px;
        }
        .social-link{
            display: flex;
            flex-wrap: wrap;
        }
        .social-link a:not(:last-child){
            margin-bottom: 7px;
        }
    }
    #single-news p{
        font-size: 16px;
    }
    #news .card{
        margin: auto;
        margin-bottom: 30px;
        max-width: 305px !important;
    }
    
    .breadcrumbs .bred-title {
        font-size: 27px;
        line-height: 30px;
    }
    #home-products {
        img{
            width: 90px;
            height: 90px;
        }
        .card-link{
            margin-top: 30px;
        }
    }
    #home-about .text, #fealiyyet .text{
        font-size: 17px;
    }
    #home-about .about-img:before, #fealiyyet .about-img:before{
        left: 0px;
        bottom: -30px;
        border-radius: 50px 0;
    }
    #home-about img, #fealiyyet img{
        border-radius: 50px 0;
    }
    #home-about .about-img, #fealiyyet .about-img{
        padding-left: 30px;
    }
    #homeMenu .col-lg-2 img, #homeMenu .col-lg-5 img{
        height: 300px;    
    }
    .title{
        font-size: 25px;
        line-height: 29px;
    }
    .subtitle {
        font-size: 17px;
        margin-bottom: 3px;
    }
    #home-contact{
        .title{
            margin-bottom: 5px;
        }
        form{
            flex-direction: column;
            div{
                max-width: 100%;
                margin-top: 20px;
            }
            input{
                width: 100%;
            }
        }
    } 
    .wp-btn, .top-btn{
        right: 20px;
    }
    footer {
        ul li{
            font-size: 17px;
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 10px;
        }
        .copyright{
            margin-top: 20px;
            flex-direction: column;
        }
        .footer-bottom{
            margin-top: 70px;
            p{
                font-size: 18px;
                line-height: 18px;
            }
        }
        .footer-contact{
            p,a {
                font-size: 16px;
            }
            &> div{
                flex-direction: column;
                &>a{
                    margin-left: 0;
                }
            }
        } 
    }
    .vacancy-form-bottom{
        flex-direction: column !important;
    }
}
