#vacancy{
    transition: $transition; 
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url('../images/bg.png');
        background-repeat: repeat;
        padding: 0;
        opacity: 0.7;
    }
    .vacancy-list{
        display: flex;
        flex-wrap: wrap;
        max-width: 1220px;
        margin: auto;
    }
    .vacancy-item{
        background: #FFFFFF;
        box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
        width: 386px;
        
        margin-bottom: 20px;
        padding: 30px 20px;
        position: relative;
        &:hover{
            .vacancy-btn{
                background: #66B64D;
                color: white;
                span{
                    background: #FBFBFB;
                    color: #66B64D;
                }
            }
        }
        &:not(:nth-child(3n+0)){
            margin-right: 20px;  
        }
    }
    .vacancy-btn{
        background: #FBFBFB;
        box-shadow: 0px 2px 7px rgba(146, 146, 146, 0.25);
        border-radius: 70px;
        padding: 4px 25px 3px 0;
        margin-bottom: 30px;
        display: inline-block;
        span{
            background: #66B64D;
            border-radius: 70px 160px 0px 70px;
            padding: 8px 40px;
            color: white;
            transition: $transition;
            margin-right: 10px;
            svg{
                font-size: 30px;
            }
        }
        
    }
    .vacancy-title{
        font-size: 20px;
        color: #66B64D;
        margin-bottom: 15px;
    }
    .vacancy-date{
        text-align: right;
        font-size: 13px;
        span{
            font-size: 13px;
            margin-right: 30px;
        }
    }
    .vacancy-subtitle{
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 45px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}
#vacancy-contact{
    max-width: 1220px;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
    padding: 80px;
    position: relative;
    z-index: 1;
    .title{
        color:#66B64D;
        margin-bottom: 30px;
        width: 100%;
    }
    p{
        margin-bottom: 15px;
        margin-top: 15px;
    }
    p, a{
        font-weight: 600;
        
    }
    p, a, svg{
        color: #000;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .vacancy-contact-text{
            position: relative;
            &::before{
                content: '';
                background: #CBCBCB;
                transform: rotate(90deg);
                width: 85px;
                height: 1px;
                position: absolute;
                right: 0;
                right: -130px;
                top: 39px;
            }
        }
    }
    h3{
        font-family: $font2;
        font-size: 20px;
        color: #66B64D;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .social-link{
        a:not(:last-child){
            margin-right: 50px;
        }
    }
    a:hover, a:hover svg{
        color: #66B64D;
    }
    .contact-phone a:not(:last-child){
        margin-right: 50px;
    }
}

.vacancy-single-section, .vacancy-contact-page{
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url('../images/bg.png');
        background-repeat: repeat;
        padding: 0;
        opacity: 0.7;
        z-index: -1;
    }
    
}
.vacancy-single-section{
    padding-bottom: 110px;
}
.vacancy-contact-page{
    padding-bottom: 80px;
}
#vacancy-single{
    background: #FFFFFF;
    box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
    padding: 10px 80px 60px;
    max-width: 995px;
    margin: auto;
    margin-top: 100px;
    // position: relative;
    // z-index: 1;
    .vacancy-title{
        font-weight: 700;
        font-size: 20px;
        color: #66B64D;
        margin-top: 50px;
    }
    .vacancy-txt{
        font-weight: 600;
        font-size: 16px;
        color: #3F3F3F;
    }
    ul{
        list-style: none;
        padding-left: 19px;
        li{
            position: relative;
            font-weight: 600;
            font-size: 16px;
            color: #3F3F3F;
            &::before{
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #3F3F3F;
                left: -11px;
                top: 9px;
            }
            &:not(:last-child){
                margin-bottom: 7px;
            }
        }
    }
    .button{
        margin-top: 22px;
    }
}

.modal-container{
    background: rgba(149, 149, 149, 0.4);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-box{
        max-width: 792px;
        width: 100%;
        padding: 50px;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
        position: relative;
        &>svg{
            color: #66B64D;
            position: absolute;
            right: 30px;
            top: 30px;
            font-size: 22px;
            cursor: pointer;
        }
    }
    
    .modal-title{
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        color: #000;
        margin-bottom: 50px;
    }
    label{
        font-family: $font2;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #69768B;
        padding-left: 20px;
        margin-bottom: 3px;
    }
    input, select{
        background: #FFFFFF;
        box-shadow: 0px 2px 7px rgba(146, 146, 146, 0.25);
        border-radius: 40px;
        border: 1px solid white;
        transition: $transition;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 40px;
        &:focus{
            border-color: #66B64D;
        }
    }
    form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .button{
            margin-top: 0 !important;
        }
    }
    form>div{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    form>div:nth-child(1), form>div:nth-child(2),  form>div:nth-child(6), form>div:nth-child(7){
        width: 48%;
    }
    form>div:nth-child(3), form>div:nth-child(4), form>div:nth-child(5){
        width: 31%;
    }
    form>div:nth-child(8), form>div:nth-child(9){
        width: 100%;
    }
    form>div:nth-child(8){
        background: #F5F5F5;
        border: 1px dashed #D7D7DA;
        border-radius: 20px;
        position: relative;
        input{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
        label{
            display: block;
            text-align: center;
            padding: 30px 0;
            font-size: 16px;
        }
    }
   
}

.vacancy-form-bottom{
    flex-direction: row !important;
    justify-content: space-between;
}