#menu{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: #00421D;
    z-index: 10000;
    // overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/bg-color.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        opacity: 0.5;
    }
    a, h2, svg,p, span{
        color: #FFFFFF;
    }
    .container{
        display: flex;
        align-items: center;
        // height: 100vh;
        margin-top: 190px;
    }
    ul{
        list-style: none;
        padding-left: 0;
        width: 49%;
        li{
            
            opacity: 0;
            transform: translate(-400px, 0px);
        }
        a{
            display: inline-block;
            position: relative;
            font-size: 40px;
            margin-bottom: 10px;
            &:before{
                content: '';
                position: absolute;
                left: 0;
            }
            &.active{
                color: #FAC617;
            }
        }
        li:nth-child(1){
            transition: opacity .3s .9s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .9s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(2){
            transition: opacity .3s .8s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .8s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(3){
            transition: opacity .3s .7s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .7s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(4){
            transition: opacity .3s .6s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(5){
            transition: opacity .3s .5s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .5s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(6){
            transition: opacity .3s .4s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .4s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
    .menu-contact{
        &>a{
            display: block;
            margin-bottom: 10px;
        }
        
    }
    .menu-container{
        width: 49%;
        transform: translateY(60px);
        opacity: 0;
        transition: opacity 0.5s .8s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s .8s cubic-bezier(0.215, 0.61, 0.355, 1);
        p{
            max-width: 550px;
            width: 100%;
            font-size: 18px;
        }
    }
    .title{
        font-size: 45px;
        margin-bottom: 20px;
    }
    .social-link{
        display: flex;
        align-items: baseline;
        margin-top: 30px;
        opacity: 0;
        transition: opacity 0.5s .6s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(70px);
        a{
            display: inline-block;
            height: 27px;
        }
        .mail{
            font-size: 27px;
            margin-right: 30px;
        }
        .insta{
            font-size: 20px;
            margin-right: 30px;
        }
        .fb{
            font-size: 20px;
        }
    }
    .menu-contact{
        color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-top: 70px;
        opacity: 0;
        transition: opacity 0.5s .7s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s .7s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateY(70px);
        a{
            margin-bottom: 0;
            font-size: 15px;
            &:first-child{
                margin-right: 10px;
                position: relative;
                &:before{
                    content: '/';
                    position: absolute;
                    right: -13px;
                    top: 0;
                }
            }   
            &:nth-child(2){
                margin-left: 10px;
            }   
        }
        p{
            font-size: 16px;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    a:hover, svg:hover{
        color: #FAC617;
    }
}

#menu.show{
    opacity: 1;
    visibility: visible;
    transition: opacity 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1);
    background: #00421D;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/bg-color.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        opacity: 0.5;
    }
    ul{
        li{
            opacity: 1;
            transform: translate(0px, 0px);
        }
        li:nth-child(1){
            transition: opacity .3s .4s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .4s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(2){
            transition: opacity .3s .5s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .5s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(3){
            transition: opacity .3s .6s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(4){
            transition: opacity .3s .7s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .7s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(5){
            transition: opacity .3s .8s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .8s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        li:nth-child(6){
            transition: opacity .3s .9s cubic-bezier(0.215, 0.61, 0.355, 1), transform .3s .9s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        
    }
    .menu-container{
        transform: translateY(0px);
        opacity: 1;
        transition: opacity .5s .8s cubic-bezier(0.215, 0.61, 0.355, 1), transform .5s .8s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .menu-contact{
        transform: translateY(0px);
        opacity: 1;
        transition: opacity .5s .9s cubic-bezier(0.215, 0.61, 0.355, 1), transform .5s .9s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .social-link{
        transform: translateY(0px);
        opacity: 1;
        transition: opacity .5s 1.1s cubic-bezier(0.215, 0.61, 0.355, 1), transform .5s 1.1s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}