#home-about{
    .subtitle{
        color: #466E61;
        font-family: $font2;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
    }
}
#about-page{
    padding-top: 0;
    padding-bottom: 60px;
}
#home-about, #fealiyyet{
    overflow: hidden;
    position: relative;
    background: #F4F4F4;
    padding: 70px 140px 120px;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        opacity: 0.7;
    }
    .title{
        margin-bottom: 15px;
    }
    .text{
        font-family: $font2;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        max-width: 530px;
    }
    .about-img{
        position: relative;
        max-width: 550px;
        height: auto;
        width: 100%;
        height: 100%;
        &:before{
            content: '';
            position: absolute;
            border-radius: 120px 0px;
            border: 1px solid #1B1B1B;
            left: -35px;
            top: 40px;
            bottom: -40px;
            right: 50px;
            transition: $transition;
        }
        &:hover:before{
            left: 0;
            bottom: 0;
            top: 0;
            right:0;
        }
    }
    img{
        border-radius: 120px 0px;
        width: 100%;
        z-index: 1;
        position: relative;
    }
    .row{
        align-items: center;
    }
}
.about-header{
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(92, 92, 92, 0.25);
    padding: 22px 0;
    .container{
        text-align: center;
    }
    a{
        font-weight: 600;
        color: #909090;
        position: relative;
        &:before{
            position: absolute;
            content: '';
            top: 8px;
            left: -10px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #909090;
        }
        &:not(:last-child){
            margin-right: 50px;
        }
        &.active, &:hover{
            color: #1B1B1B;
        }
    }
}
#about{
    padding:90px 0;
    p{
        margin: auto;
        font-weight: 500;
        max-width: 956px;
        width: 100%;
        margin-bottom: 15px;
    }
}
#partner{
    padding-top: 90px;
    .title{
        text-align: center;
        margin-bottom: 40px;
    }
    .partner-img{
        display: flex;
        flex-wrap: wrap;
        max-width: 750px;
        margin: auto;
        width: 100%;
        justify-content: space-around;
    }
    img{
        filter: grayscale(1);
        transition: $transition;
        cursor: pointer;
        max-width: 140px;
        width: 100%;
        margin-bottom: 30px;
        object-fit: contain;
        margin-right: 40px;
        &:hover{
            filter: grayscale(0);
        }
    }
}