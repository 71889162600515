#home-contact{
    background: #F2FFF2;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        opacity: 0.8;
    }
    .title{
        max-width: 580px;
        margin-bottom: 35px;
    }
    div.container{
        max-width: 740px;
    }
    form{
        display: flex;
        flex-wrap: wrap;
        div{
            position: relative;
            max-width: 50%;
            width: 100%;
        }
        div:nth-child(3){
            width: 100%;
            max-width: 100%;
            margin-top: 15px;
        }
        div:nth-child(2){
            input, p{           
                margin-left: 50px;
            }
            label{
                left: 60px;
            }
        }
        input, textarea{
            border: none;
            border-bottom: 1.5px solid #466E61;
            background: transparent;
            margin-top: 20px;
            padding-left: 10px;
            height: 30px;
        }
        label{
            color: #466E61;
        }
        button{
            width: 100%;
        }
    }
    
    label {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        transition: 0.4s;
        font-size: 16px;
        font-weight: 400;
    }
    textarea + label{
        bottom: -4px;

    }
    input:focus, textarea:focus {
        border-color: $black;
    }
    textarea{
        width: 100%;
    }
    input{
        width: 305px;
    }
    input:focus + label,
    input:not(:placeholder-shown) + label, textarea:focus + label,
    textarea:not(:placeholder-shown) + label  {
        top: 0;
        transition: 0.3s;
        background-color: transparent;
        color: #466E61;
      
    }
    textarea:focus + label,
    textarea:not(:placeholder-shown) + label {
        transform: translateY(-14%);
    }
    .form-btn{
        border: 1.5px solid #466E61;
        border-radius: 70px;
        background: transparent;
        margin-top: 45px;
        padding: 8px 0;
        font-size: 16px;
        font-weight: 600;
        &:hover{
            background-color: #466E61;
            color: white;
        }
    }
    
    .Toastify{
        position: relative;
        z-index: 10000000;
    }
}
.error-message{
    color: red;
    font-size: 14px;
    margin-bottom: 0;
}
#contact{
    margin-bottom: 70px;
    .map{
        max-width: 600px;
        min-height: 580px;
        position: relative;
        width: 50%;
        &:before{
            content: '';
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            bottom: -60px;
            border: 1px solid #1B1B1B;
            border-radius: 120px 0px;
        }
        iframe{
            border-radius: 0 120px 0 0;
            min-height: 580px;
            border: 1px solid #1B1B1B;
            margin-left: 40px;
        }
    }
    .contact-info{
        background: #FFFBF4;
        box-shadow: 2px 0px 10px rgba(92, 92, 92, 0.25);
        width: 50%;
        min-height: 395px;
        padding: 50px 40px 50px 90px;
    }
    .container{
        display: flex;
        align-items: center;
    }
    h2, h3{
        font-family: $font2;
        color: #66B64D;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h2{
        font-size: 32px;
    }
    h3{
        font-size: 22px;
        margin-top: 50px;
    }
    p,a{
        font-weight: 600;
        color: #000;
    }
    p{
        margin-bottom: 15px;
    }
    .contact-phone{
        display: flex;
        flex-wrap: wrap;
        a{
            margin-bottom: 8px;
        }
        a:not(:last-child){
            margin-right: 50px;
        }
    }
    .social-links{
        display: flex;
        a:not(:last-child){
            margin-right: 50px;
        }
    }
    a:hover{
        color: #66B64D;
    }
}