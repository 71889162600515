header{
    z-index: 10001;
    img{
        height: 80px;
    }
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-top{
        background: $green;
        padding: 9px 0;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.15);
        border-bottom: 1px solid white;
    }
    .header-top>.container>div:first-child{
        display: flex;
        color: white;
        font-size: 15px;
        font-weight: 600;
        align-items: center;
        p{
            margin-bottom: 0;
            margin-left: 25px;
        }
        a,p{
            color: white;
            font-size: 15px;
            font-weight: 600;
        }
        a{
            font-size: 14px;
            position: relative;
            &:before{
                content: '/';
                position: absolute;
                right: -13px;
                top: 0;
            }
            &:hover{
                color: #F2FFF2;
            }
        }
    }
    .header-top>.container>div:last-child{
        a{
            color: white;
        }
        svg{
            font-size: 19px;
        }
        .mail svg{
            font-size: 23px;
            margin-right: 24px;
        }
        .insta svg {
            font-size: 17px;
            margin-right: 20px;
        }
    }
    .header-bottom{
        padding: 15px 0;
        background: transparent;
        position: relative;
        z-index: 100001;
    }
    .header-right{
        display: flex;
        align-items: center;
        width: 130px;
    }
    .menu-btn-div{
        position: relative;
        cursor: pointer;
        z-index: 10000;
        width: 42px;
        height: 42px;
        margin-left: 32px;
        &:before, &:after{
            content: '';
            background: #333;
            height: 2px;
            transition: $transition;
            cursor: pointer;
            position: absolute;
        }
        &:before{
            transform: translateY(15px);
            width: 32px;
        }
        &:after{
            transform: translateY(27px);
            width: 18px;
        }
        .menu-btn{
            background: #333;
            width: 42px;
            height: 2px;
            transition: $transition;
            transform: translate(0px, 22px);
            width: 26px;
        }
        
    }
    .lang, .lang li, .lang:before{
        transition: $transition;
        color:#333;
        cursor: pointer;
    }
    .lang li:hover{
        color: #66B64D !important;
    }
    
}
header.header-home{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .menu-btn-div{
        .menu-btn, &:after, &:before{
            background: white;
        }
    }
    .lang, .lang li, .lang:before{
        color:white;
    }
}
.lang{
    display: flex;
    list-style: none;
    max-width: 58px;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 0;
    position: relative;
    &:before{
        content: "/";
        position: absolute;
        left: 47%;
        color: white;
        top: -2px;
    }
    li{
        font-weight: 600;
    }
    .active{
        display: none;
    }
}

header.show{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(92, 92, 92, 0.25);
    animation: scroll 0.6s linear forwards;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // transition: background-color 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    .lang, .lang li, .lang:before{
        color: #333;
    }
    .menu-btn-div{
        .menu-btn, &:after, &:before{
            background: #333;

        }
    }
    .header-top{
        box-shadow: none;
    }
}
@keyframes scroll {
    0% {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

header.show-header{
    background-color: transparent;
    box-shadow: none;
    transition: background-color 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1);
    .header-top{
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s .2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .menu-btn-div  .menu-btn{
        margin-left: -20px;
        opacity: 0;
    }
    .menu-btn-div:before{
        transform: translateY(15px) rotate(45deg);
    }
    .menu-btn-div:after{
        transform: translateY(13px) rotate(135deg);
        width: 32px;
    }
    .lang, .lang li, .lang:before{
        color: white;
    }
    .menu-btn-div{
        .menu-btn, &:after, &:before{
            background: white;
         
        }
    }
}
header{
    .header-top{
        opacity: 1;
        visibility: visible;
        transition: opacity 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), visibility 1s 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}