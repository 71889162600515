.button{
    background: #66B64D;
    border: 1px solid #66B64D;
    border-radius: 70px;
    display: inline-block;
    color: white;
    padding: 9px 50px;
    &:hover{
        background: white;
        color: #66B64D;
    }
}

.wp-btn, .top-btn{
    z-index: 100;
    width: 50px;
    height: 50px;
    background: $green;
    border: 1px solid $green;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    position: fixed;
    right: 50px;
    bottom: 70px;
    cursor: pointer;
    transform: rotate(45deg);
    &:before, &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 1px solid $green;
        z-index: -1;
        opacity: 0; 
        animation: pulse-border-2 1s linear infinite; 
     
       
    }
    &:after{
        animation: pulse-border 1.4s linear infinite; 
    }
    svg{
        color: white;
        font-size: 30px;
        transform: rotate(-45deg);
    }
    &:hover{
        background: white;
        svg{
            color: $green;
        }
    }
}
.wp-btn{
    bottom: 165px;

}


@keyframes pulse-border-2 {
    0% {
      transform: scale(1);
      opacity: 1; }
    100% {
      transform: scale(1.5);
      opacity: 0; } }
@keyframes pulse-border {
    0% {
      transform: scale(1);
      opacity: 1; }
    100% {
      transform: scale(1.8);
      opacity: 0; } 
}
  