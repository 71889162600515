footer{
    background: #466E61;
    position: relative;
    padding-top: 90px;
    overflow: hidden;
    a,p, li{
        color:white;
        z-index: 2;
        position: relative;
    }
    a:hover, svg:hover{
        color: #FAC617 !important;
    }
    &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../images/bg-color.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        opacity: 0.5;
    }
    ul{
        list-style: none;
        padding-left: 170px;
        display: flex;
        flex-wrap: wrap;
        li{
            font-weight: 600;
            font-size: 19px;
            max-width: 50%;
            width: 100%;
            margin-bottom: 10px;
            a:hover{
                padding-left: 15px;
            }
        }
    }
    p{margin-bottom: 0;}
    .copyright{
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        max-width: 55%;
        a, p{
            font-size: 14px;
        }
        a{
            color: white;
        } 
        img{
            height: 13px;
            filter: invert(1) brightness(2.5);
        }
    }
    .footer-bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        a:not(:last-child){
            margin-right: 10px;
        }
        img{
            margin-bottom: 15px;
            height: 100px;
        }
        p{
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 15px;
        }
        svg{
            color: white;
            font-size: 21px;
        }
        a.mail{
            margin-right: 15px;
            svg{
                font-size: 25px;
            }
        }
        .insta{
            svg{
                font-size: 19px;
            }
        }
    }
    .social-link{
        transform: translateY(20px);
    }
    .footer-contact{
        p, a{
            font-size: 19px;
            margin-bottom: 10px;
        }
        &>div{
            display: flex;
            .contact{
                display: flex;
                flex-direction: column;
            }
            &>a{
                margin-left: 50px;
            }
        }
    }
    
}