.breadcrumbs{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 100%;
    width: 100%;
    padding-top: 30px;
    position: relative;
    .container{
        min-height: 490px;
        position: relative;
    }
    &:before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    ul{
        list-style: none;
        display: flex;
        padding-left: 0;
        margin: 0;
    }
    li{
        color: #fff;
        &:not(:last-child){
            margin-right: 30px;
        }
    }
    li, a{
        font-weight: 600;
    }
    a{
        color: #fff;
        position: relative;
        display: inline-block;
        &:before{
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            right: -18px;
            top: 10px;
            background: #66B64D;
        }
        &:hover{
            color: #FAC617;
        }
    }
    .bred-subtitle, .bred-title{
        color: white;
    }
    .bred-subtitle{
        font-family: $font2;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 5px;
    }
    .bred-title{
        font-family: $font1;
        font-weight: 350;
        font-size: 40px;
        max-width: 860px;
        line-height: 44px;
    }

    .bred-text{
        position: absolute;
        bottom: 40px;
    }
}

#breadcrumbs-contact{
    background-image: url('../images/bred-elaqe.jpg');
}
#breadcrumbs-about{
    background-image: url('../images/bred-haqqimizda.jpg');
}
#breadcrumbs-news{
    background-image: url('../images/bred-news.jpg');
}
#breadcrumbs-news-single{
    background-image: url('../images/bred-news-single.jpg');
}
#breadcrumbs-vacancy{
    background-image: url('../images/vacancy-bred.jpg');
}
#breadcrumbs-gallery{
    background-image: url('../images/bred-gallery.jpg');
}
#breadcrumbs-product{
    background-image: url('../images/bred-products.jpg');
    background-position: center top;
}