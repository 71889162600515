#home-products{
    padding: 70px 0;
    .card{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: none;
        max-width: 386px;
        position: relative;
        margin: auto;
        padding: 30px;
        transition: $transition;
        &:hover{
            box-shadow: 0px 4px 18px rgba(0,0,0, 0.13);
        }
    }
    .card-link{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }
    img{
        width: 125px;
        height: 125px;
    }
    .card-title{
        font-family: $font2;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .card-subtitle{
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }
}

#products{
    padding-top: 0;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url('../images/bg.png');
        background-repeat: repeat;
        opacity: 0.7;
    }
    .products-container{
        padding-top: 90px;
        .product{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 85px 80px;
            .product-img{
                max-width: 550px;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    border-radius: 0px 120px;
                    border: 1px solid #1B1B1B;
                    left: -35px;
                    top: 40px;
                    bottom: -40px;
                    right: 50px;
                    transition: $transition;
                }
                &:hover:before{
                    left: 0;
                    bottom: 0;
                    top: 0;
                    right:0;
                }
                img{
                    border-radius: 0px 120px;
                    width: 100%;
                    z-index: 1;
                    position: relative;
                    filter: drop-shadow(0px 1px 30px rgba(117, 117, 117, 0.25));
                }
            }
            &:last-child{
                padding-bottom: 0;
            }
            &:nth-child(even){
                flex-direction: row-reverse;
                .product-img{
                    &:before{
                        border-radius: 120px 0px;
                    }
                    img{
                        border-radius:120px 0px;
                    }
                }
            }
        }
        .product-text{
            max-width: 490px;
        }
        .product-title{
            font-family: $font2;
            font-weight: 700;
            position: relative;
            padding-left: 10px;
            
        }
        
    }
}
